.product-price .new-price-custom {
  color: #333;
  font-size: 26px;
  text-decoration: none;
  font-family: LatoBlack;
}
.product-media {
  .img-product-list-custom {
    height: 230px !important;
    object-fit: contain !important;
  }
}
.product-name-custom {
  height: 45px;

  a {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}

.product-widget {
  .new-price {
    font-family: LatoBlack;
  }
}

.product-custom {
  transition:
    box-shadow 0.3s,
    opacity 0.3s,
    -webkit-box-shadow 0.3s !important;
  padding: 0.9rem;
  border: 1px solid #eee;
  border-radius: 0.3rem !important;
  background-color: #fff;

  .product-hidden-details {
    padding: 0.9rem !important;
  }
}
.product-label-group {
  left: 1rem;
  right: 1rem;
}
.title-special-block-custom {
  font-family: LatoBold;
  font-size: 22px;
}
.btn-show-all-special-custom {
  font-size: 16px;
}
.product-single-swiper {
  img {
    min-height: 100% !important;
  }
}

.sections-category-list {
  img {
    height: 115px !important;
    object-fit: contain !important;
  }
}
.banner-product-wrapper {
  margin-bottom: 3.2rem;
}
.banner-product-wrapper .banner {
  height: calc(100% - 2.4rem);
  min-height: 25rem;
}
.banner-product-wrapper .banner-content {
  bottom: 2.7rem;
  left: 9.1%;
  right: 9.1%;
}
.banner-product-wrapper .banner-subtitle {
  font-size: 1.8em;
  margin-bottom: 0.9rem;
}
.banner-product-wrapper .banner-title {
  margin-bottom: 0.8rem;
  font-size: 3em;
  line-height: 1.17;
}
.banner-product-wrapper .banner-price-info {
  font-size: 1.8em;
  margin-bottom: 2.7rem;
}
.old-price-custom-div {
  font-size: 14px;
  opacity: 0.5;
  margin-bottom: -5px;
}
.product-price-holder-custom {
  height: 75px;
  display: flex !important;
  justify-content: flex-end;
  flex-direction: column;
}
.new-price.new-price-custom small {
  font-size: 20px;
}
.payment-price-custom {
  margin-top: -5px;
  color: #ff6206;
}
.category-menu > li > a {
  text-transform: uppercase;
}
.tab-content table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.product-detail-price-custom {
  height: 100%;
  .old-price-custom-div {
    margin-bottom: 0px;
    font-size: 25px;
  }
  .new-price-custom {
    font-size: 40px;
  }
  .new-price.new-price-custom small {
    font-size: 29px;
  }
  .payment-price-custom small {
    font-size: 18px;
  }
  .payment-price-custom {
    font-size: 23px;
    margin-top: 0;
    margin-bottom: 25px;
  }
}
hr {
  background-color: #ccc !important;
}
.product-categories-detail-custom {
  font-size: 1.5rem !important;
  color: #000 !important;
}
.product-categories-detail-custom span {
  color: #000 !important;
}
.product-categories-detail-custom b {
  font-family: "LatoBlack";
}
.product-detail-title-custom {
  margin-bottom: 15px !important;
  color: #000 !important;
  line-height: 32px !important;
  font-size: 28px !important;
  font-family: "LatoBlack";
}
ul.shipping-helper {
  display: block;
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 4px;
}

ul.shipping-helper:after {
  content: "";
  display: block;
  position: relative;
  clear: both;
}

ul.shipping-helper .stockCheckContainer {
  display: none;
  position: absolute;
  width: calc(200% + 50px);
  left: -40px;
  background: #f3f3f3;
  padding: 10px 20px;
  z-index: 99;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-top: 5px;
}

ul.shipping-helper .stockCheckContainer.visibleSCC,
ul.shipping-helper .stockCheckContainer:hover {
  display: block;
}

ul.shipping-helper .stockCheckContainer p {
  font-size: 14px;
  height: 49px;
  text-align: center;
  overflow: hidden;
  font-weight: 300;
}

ul.shipping-helper .stockCheckContainer textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  background: #fff;
  box-shadow: none;
  resize: none;
  border: none;
}

ul.shipping-helper .stockCheckContainer button {
  display: inline-block;
  position: relative;
  background: #33373f;
  color: #fff;
  padding: 5px 0;
  margin: 40px auto;
  width: 100%;
  text-align: center;
  border: none;
  outline: none;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  line-height: 30px;
}

ul.shipping-helper .stockCheckContainer button:disabled {
  cursor: auto;
}

ul.shipping-helper .stockCheckContainer button .spinner {
  vertical-align: middle;
}

ul.shipping-helper li {
  display: flex;
  align-items: center;
  grid-gap: 12px;
  gap: 12px;
  position: relative;
  font-weight: 500;
  font-size: 1.5rem !important;
  margin-bottom: 10px;
}

ul.shipping-helper li.ok:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background: #85c14a;
  border-radius: 50%;
}

ul.shipping-helper li.check {
  margin-left: 22px;
}

ul.shipping-helper li.check:hover .stockCheckContainer {
  display: block;
}

ul.shipping-helper li.check:after {
  content: "";
  display: block;
  position: absolute;
  left: -22px;
  top: 6px;
  width: 10px;
  height: 10px;
  background: #fdd657;
  border-radius: 10px;
}
.product-image.product-image-custom-produc-detail img {
  height: 380px;
  object-fit: contain;
}
.product-detail-page-content .main-content .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-detail-page-content .swiper-slide .main-content img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination-bullet-active {
  background-color: #ff6206 !important;
}
.font-custom-category-all-menu {
  font-family: LatoBlack;
}
.product-image.product-image-custom-produc-detail {
  //   border: 1px solid #eeeeee;
  //   border-radius: 0.5rem !important;
}
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -3px !important;
}
.product-thumb-custom-product-detail {
  //   border: 1px solid #eeeeee !important;
  //   border-radius: 0.5rem !important;
}
.swiper-button-prev,
.swiper-button-next {
  color: #ff6206 !important;
}
.header-right-custom {
  flex: none !important;
}
.swiper-horizontal {
  padding-bottom: 25px !important;
}
.homepage-list-categories-items {
  margin-bottom: 30px;
}
.product-detail-page-content .product-thumbs-wrap img {
  display: block;
  width: 100%;
  max-height: 110px !important;
}
.product-item-vertical-list {
  .new-price-custom {
    font-size: 20px !important;
  }
  .payment-price-custom {
    font-size: 16px !important;
    margin-top: -8px !important;
    margin-bottom: 0px !important;
  }
  .old-price-custom-div {
    margin-bottom: -8px !important;
    font-size: 17px !important;
  }
  .new-price-custom small {
    font-size: 15px !important;
  }
  .payment-price-custom small {
    font-size: 13px !important;
  }
}
.MuiPagination-root .Mui-selected {
  background-color: rgb(255 98 6) !important;
  color: #fff !important;
}
.MuiPagination-root .MuiButtonBase-root {
  font-size: 16px !important;
}
.css-n8417t-MuiSvgIcon-root-MuiPaginationItem-icon {
  font-size: 16px !important;
}
.css-ahj2mt-MuiTypography-root {
  font-size: 14px !important;
}
.error-page-container {
  text-align: center;
  margin: 110px 0px;
  h1 {
    font-size: 150px;
    color: #183544;
    margin-bottom: 20px;
  }

  p {
    font-size: 50px;
    color: #ff6206;
  }
}
.header-top {
  font-size: 16px !important;
}
.input-category-search-list {
  form {
    padding: 0px;
    border: 1px solid #183544;
    margin: 0px 0px 20px 0px !important;
  }
  .header-search.hs-expanded {
    flex: 1 1;
    margin: 0 auto;
    min-width: auto;
    max-width: 325px;
  }
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ff6206;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.button-submit-filter-mobile {
  display: none;
}
.custom-title-name-filter .MuiFormControlLabel-label {
  font-size: 14px !important;
}

.megamenu-wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px 15px 0px 0px;

  .megamenu-item {
    margin-bottom: 15px;
    flex: 0 0 25%;
    box-sizing: border-box;
    padding-right: 15px;

    .megamenu-title-line {
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 10px;
    }

    .megamenu-title {
      font-size: 14px;
      text-transform: uppercase;
      margin-bottom: 4px;
      line-height: 18px;
      color: #000;
      cursor: pointer;

      &:hover {
        color: #ff6206;
      }
    }
    .megamenu-list-item {
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 7px;
      cursor: pointer;

      &:hover {
        color: #ff6206;
      }
    }
  }
}
ul.category-list-sub li {
  margin-left: 20px;
}
.megamenu-list-item a {
  line-height: normal;
  padding: 0px !important;
}
