@media (max-width: 575px) {
  .intro-slide {
    min-height: 250px !important;
    margin-top: 15px;
  }
  .intro-slide3 .banner-title {
    margin-bottom: 0.2rem;
    font-size: 2.8em;
    font-weight: 800;
  }
  .header-middle .header-right {
    margin-left: 0 !important;
    justify-content: flex-end;
  }
  .header-call {
    margin-right: 0px !important;
  }
  .header-call::before {
    content: none !important;
  }
  .intro-slide .btn {
    padding: 8px 15px;
  }
  .swiper-wrapper-logos .swiper-horizontal {
    padding-bottom: 0px !important;
  }
  .sidebar-info-left-mobile {
    padding-top: 70px !important;
  }
  .sidebar-content {
    width: 100% !important;
  }
  .product-image.product-image-custom-produc-detail img {
    height: 250px;
    object-fit: contain;
  }
  .welcome-msg {
    padding: 8px 0px;
  }
  .header-search-mobile {
    background: #132a36;
    padding: 8px 0px;
    display: block !important;
    .header-search {
      display: flex;
      width: auto;
    }
  }
  .product-hidden-details .btn-cart {
    max-width: 100% !important;
    width: 100% !important;
  }
  .product-hidden-details .btn-cart span {
    display: block !important;
  }
  .toolbox-left {
    align-items: center;
    flex-wrap: nowrap;
    justify-content: center;
  }
  .serach-box-item-content-text {
    font-size: 24px !important;
    line-height: 26px;
    color: #000;
    font-family: LatoRegular;
  }
  .input-category-search-list {
    .header-search.hs-expanded {
      max-width: 100%;
      display: flex;
    }
  }
  .custom-title-category {
    max-width: 230px !important;
  }
  .button-submit-filter-mobile {
    display: block !important;
  }
}
